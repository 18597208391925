import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageCarousel from "../components/PageCarousel"
import { useStaticQuery, graphql } from "gatsby"

export default function Lawnmowers() {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "images/lawnmowers" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Lawnmower repair &amp; maintenance | Service Center</title>
        <meta
          name="description"
          content="At the Masterton Mitre 10 Mega Service Centre we repair and maintain most types of lawnmowers. Regularly servicing your lawnmower will extend its life."
        />
      </Helmet>

      <Layout title="Lawnmowers">
        <section className="overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <p>
                    At the Masterton Mitre10 Mega Service Centre we repair and
                    maintain most types of lawnmowers.
                  </p>
                  <p>
                    Regularly servicing your lawnmower will extend the life of
                    your machine and save you money in the long run. Even if
                    your mower is operating normally, a general service can
                    improve performance and prevent any problems further down
                    the line. A general service may include:
                  </p>
                  <ul>
                    <li>changing the oil</li>
                    <li>replacing spark plugs</li>
                    <li>replacing the air filter and fuel filters</li>
                    <li>replacing or sharpening the blades</li>
                    <li>a clean and check over by our qualified mechanic</li>
                  </ul>
                  <p>
                    We also offer a pickup and delivery service. A price will be
                    advised for this service when you give us your location
                    details.
                  </p>
                  <p>
                    If a replacement mower is needed our main store has a wide
                    range of mowers for every sie of lawn and budget. Click{" "}
                    <a
                      href="https://www.mitre10.co.nz/shop/power-garden-tools/lawn-mowers/c/RS2109"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    &nbsp;to find out more.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <PageCarousel imageData={imageData} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
